<template>
  <div class="container">
    <section class="top">
      <div class="row">
        <div class="cols-12">
          <h4>TERMOS E CONDIÇÕES DE USO DO EBLONLINE</h4>
          <br />
          <p>
            Este documento (“Termos e Condições”) regula o uso da plataforma
            tecnológica de serviços interativos de leilões e vendas online
            denominada “Empresa Brasileira de Leilões Online ou EBL Online”,
            marca em processo de registro EBLONLINE.COM.BR pertencente a empresa
            WEBZI PESQUISA & INOVAÇÃO TECNOLÓGICA LTDA, CNPJ 37.552.169/0001-64.
          </p>
          <p>
            O usuário deverá ler atentamente este documento antes de realizar
            seu cadastro. Os usuários do EBL Online podem ser compradores,
            agentes de venda que atuam em nome próprio (vendedores) ou agentes
            de venda mandatados por vendedores para a venda de bens (Leiloeiros
            Oficiais, Leiloeiros Judiciais, Pregoeiros Administrativos,
            Leiloeiros Rurais, Corretores de Imóveis, Empresas Organizadoras de
            Leilão e Empresas Intermediadoras da Venda Direta) – “usuário”.
          </p>
          <p>
            Em caso de dúvidas, o usuário poderá entrar em contato por meio do
            chat e e-mail contato@eblonline.com.br (“Canais de Atendimento”).
          </p>
          <p>
            As informações de contato dos Canais de Atendimento também estão
            disponíveis no Site EBL Online.
          </p>
          <p>
            Ao aderir a estes Termos e Condições, o usuário aceita e concorda
            com todas as regras aqui estabelecidas, as quais constituem acordo
            integral com relação ao uso do Site EBL Online, dos demais produtos,
            serviços e lojas a ele relacionados, no que for aplicável.
          </p>
          <p>
            São realizados no Site EBL Online os seguintes eventos: (i) leilões
            públicos, (ii) leilões privados e (iii) vendas diretas com
            confirmação de compra - “Compre Já”, recebimento de propostas, ou
            híbrida (confirmação de compra - “Compre Já” + proposta), sendo cada
            uma dessas modalidades sujeita a regras específicas.
          </p>
          <p><b>1. CADASTRO</b></p>
          <p>
            1.1. Para participar dos eventos realizados no Site EBL Online e
            utilizar todas as suas funcionalidades, o usuário deverá realizar o
            seu cadastro no Site www.eblonline.com.br, mediante preenchimento
            das informações e fornecimento dos documentos solicitados.
          </p>
          <p>
            1.2. O Site EBL Online poderá, a qualquer momento, entrar em contato
            com o usuário para solicitar o envio de dados e/ou documentos
            adicionais, com o intuito de esclarecer, revisar ou aprovar o
            cadastro do usuário e as informações por ele fornecidas.
          </p>
          <p>
            1.3. As informações e os documentos exigidos para a realização do
            cadastro podem variar de acordo com o usuário em questão (pessoa
            jurídica ou pessoa física) e/ou com as funcionalidades que serão
            utilizadas, entre outros fatores.
          </p>
          <p>
            1.4. Cada usuário está autorizado a ter apenas UM cadastro a ele
            associado no Site EBL Online, seja de comprador ou de agente de
            venda. Caso seja identificado mais de um cadastro para o mesmo
            usuário, um ou todos esses cadastros poderão ser suspensos ou
            cancelados.
          </p>
          <p>
            1.5. Além das informações cadastrais mencionadas acima, o usuário do
            Site EBL Online deverá observar os seguintes requisitos para poder
            utilizar o Site EBL Online:
          </p>
          <p>
            (i) no caso de pessoas físicas, o usuário deverá ter mais de 18
            (dezoito) anos;
          </p>
          <p>
            (ii) no caso de pessoas jurídicas, deverá ser representada por
            pessoas autorizadas e com poderes para agir em seu nome, tais como
            sócios, diretores ou procuradores e, se tiver sede no Brasil, estar
            devidamente cadastrada no CNPJ, e
          </p>
          <p>
            (iii) atender a critérios mínimos de prevenção à fraude determinados
            pela EBL Online com base na legislação e normativos vigentes,
            incluindo, se necessário, a prestação de esclarecimentos e o
            fornecimento de informações ou documentação adicional às solicitadas
            inicialmente na realização do cadastro.
          </p>
          <p>
            1.6. Ao se cadastrar, o usuário deverá informar seu e-mail para sua
            identificação e login no Site EBL Online, bem como uma senha pessoal
            e intransferível.
          </p>
          <p>
            1.7. O Site EBL Online poderá impedir a utilização de logins que
            guarde semelhança com o nome EBL Online, com o nome dos agentes de
            venda dos bens divulgados no Site EBL Online, que contenha dados
            pessoais do usuário que permitam a sua identificação e/ou permitam
            que terceiros possam contatá-lo (URL, endereço eletrônico, etc.) ou
            que contenha termo considerado ofensivo.
          </p>
          <p>
            1.8. O usuário é responsável por garantir a confidencialidade do seu
            Nome de Usuário e senha criados no ato do cadastro no Site EBL
            Online, não devendo divulgá-los a terceiros. O usuário é o único e
            exclusivo responsável por todas e quaisquer atividades que ocorram
            em seu cadastro para o uso do Site EBL Online, portanto, deve ter
            cuidado ao utilizar redes públicas desconhecidas e/ou de terceiros,
            e/ou gravar senhas de forma automática em computadores, celulares,
            tablets e/ou outros dispositivos.
          </p>
          <p>
            1.9. O Site EBL Online não será, em qualquer hipótese, responsável
            por quaisquer prejuízos causados ao usuário ou a terceiros pela
            divulgação e utilização indevida de seus dados de Nome de Usuário e
            senha.
          </p>
          <p>
            1.10. O usuário declara que todas as informações e conteúdos
            enviados para fins de seu cadastro são verdadeiros, exatos, atuais e
            completos, responsabilizando-se civilmente pela veracidade e
            exatidão das informações fornecidas. O usuário também se compromete
            a manter seus dados cadastrais permanentemente atualizados e a
            enviar informações e esclarecimentos sempre que solicitado.
          </p>
          <p>
            1.11. O cadastro do usuário no Site EBL Online está condicionado à
            análise e aprovação dos dados cadastrais fornecidos pelo usuário. O
            Site EBL Online se reserva o direito de recusar qualquer solicitação
            de cadastro e/ou de suspender um cadastro previamente aceito em
            qualquer das seguintes situações:
          </p>
          <p>(i) a identidade do usuário não possa ser verificada;</p>
          <p>
            (ii) se qualquer informação fornecida pelo usuário for falsa,
            incorreta, desatualizada ou incompleta;
          </p>
          <p>
            (iii) caso seja verificada qualquer pendência financeira junto ao
            Site EBL Online, e
          </p>
          <p>
            (iv) fique demonstrado que há indícios de que o usuário cometeu
            fraude ou atos dolosos ou teve qualquer comportamento que possa, de
            qualquer modo, causar danos a terceiros ou ao Site EBL Online.
          </p>
          <p>
            1.12. O usuário tem ciência de que ao aceitar estes Termos e
            Condições de Uso e concluir seu cadastro no Site EBL Online, o Site
            EBL Online poderá, diretamente ou por meio de terceiros, realizar
            consultas a diversas fontes com a finalidade de validar a identidade
            do usuário, bem como para cumprir deveres regulatórios e legais.
          </p>
          <p>
            1.13. Uma vez concluído e aprovado o cadastro, o usuário receberá em
            até 1 dia útil permissão de acesso ao Site EBL Online, que deverá
            ser realizado por meio do Nome de Usuário e senha por ele criados
            para fazer uso das funcionalidades disponibilizadas pelo Site EBL
            Online.
          </p>
          <p>
            1.14. Quando houver alteração dos dados cadastrais, é obrigação do
            usuário atualizá-los no Site EBL Online.
          </p>
          <p><b>2. MODALIDADES DE VENDA</b></p>
          <p>2.1. Leilões Públicos</p>
          <p>
            2.1.1. Evento público em que os bens são vendidos a quem ofertar o
            maior valor (lance). Os leilões públicos são realizados por
            Leiloeiros Oficiais, Leiloeiros Judiciais, Leiloeiros Rurais e
            Pregoeiros Administrativos legalmente habilitados para o exercício
            das funções, em data, horário e local previamente determinados e
            divulgados em jornais de grande circulação e no Site EBL Online.
            Todas as regras, prazos e condições de pagamento constarão das
            Condições de Venda e Pagamento do evento.
          </p>
          <p>2.2. Leilões Privados</p>
          <p>
            2.2.1. O leilão privado é um evento restrito a convidados e é
            acessado através de URL própria. Todas as regras, prazos e condições
            de pagamento constarão das Condições de Venda e Pagamento do evento.
          </p>
          <p>2.3. Venda Direta com Confirmação de Compra – Compre JÁ</p>
          <p>
            2.3.1.Modalidade de venda em que o interessado em adquirir o bem
            ofertado pelo valor indicado no Site EBL Online deve confirmar a
            compra clicando no botão “Compre Já” e realizar o pagamento do bem
            conforme Condições de Venda e Pagamento do evento.
          </p>
          <p>2.4. Venda Direta com Recebimento de Propostas</p>
          <p>
            2.4.1. Modalidade de venda em que o interessado em adquirir o bem
            deverá encaminhar proposta para aquisição do bem ofertado. A
            proposta encaminhada será analisada pelo vendedor. Todas as regras,
            prazos e condições de pagamento constarão das Condições de Venda e
            Pagamento do evento.
          </p>
          <p><b>3. PROCEDIMENTO PARA VENDA DE BENS NO SITE EBL ONLINE</b></p>
          <p>
            3.1. Para vender bens no Site EBL Online, os agentes de venda devem
            preencher os seguintes requisitos:
          </p>
          <ol type="1">
            <li>
              ter CNPJ ou CPF ativo e sem restrições ou ser Leiloeiro Oficial
              matriculado em Junta Comercial;
            </li>
            <li>
              ter situação regular junto à Receita Federal, Junta Comercial,
              Órgãos de Proteção ao Crédito, Cartórios de Protesto, Banco
              Central, Poder Judiciário, dentre outros;
            </li>
            <li>
              não ter status no Cadastro Nacional de Empresas Inidôneas e
              Suspensas do Portal da Transparência;
            </li>
            <li>
              não ter capital social inferior a R$ 1.000,00 (mil reais), no caso
              de Pessoa Jurídica.
            </li>
          </ol>
          <p>
            3.2. Os agentes de venda devem assinar contrato para utilização dos
            serviços do Site EBL Online.
          </p>
          <p>
            3.3. Os agentes de venda devem cadastrar no Site EBL Online os
            eventos que pretendem realizar, conforme as modalidades disponíveis
            no Site EBL Online e os bens que serão vendidos, os quais deverão
            ser vinculados aos respectivos proprietários.
          </p>
          <p>
            3.4. Deve ser igualmente cadastrada no Site EBL Online pelos agentes
            de venda a forma de pagamento dos bens, e as Condições de Venda e
            Pagamento do evento.
          </p>
          <p>
            3.5. Os agentes de venda serão responsáveis pelo atendimento de 2º
            nível aos usuários compradores, ou seja, esclarecimento de dúvidas
            sobre os eventos, bens, pré e pós-venda, conforme exemplificado
            abaixo:
          </p>
          <ol type="1">
            <li>
              informações técnicas sobre os bens quanto ao funcionamento, dados
              adicionais, fotos, etc;
            </li>
            <li>
              orientações adicionais quanto às regras e procedimentos de
              visitação dos bens;
            </li>
            <li>
              gestão dos eventos restritos ou privados para oferta de
              lances/propostas;
            </li>
            <li>
              gestão e análise das solicitações de cancelamento de
              lance/proposta. O ofertante do lance/proposta deverá receber a
              formalização quanto ao cancelamento ou não do lance/proposta.
              Quando houver decisão de cancelamento do lance, o Site EBL Online
              deverá receber do agente de venda a formalização em tempo hábil
              para execução do cancelamento no Site EBL Online, no caso de
              evento em andamento. O agente de venda é responsável pela
              comunicação e formalização do cancelamento do lance/proposta aos
              demais participantes do evento, se houver. Quando constatados
              lances/propostas em duplicidade ou lance/proposta ofertado
              erroneamente por manuseio do incremento, a solicitação será
              aprovada imediatamente pelo Site EBL Online;
            </li>
            <li>cobrança e prorrogação de prazos de pagamento;</li>
            <li>
              informações adicionais sobre as regras e procedimentos necessários
              para retiradas dos bens;
            </li>
            <li>
              atualizações, atrasos ou quaisquer outros assuntos referentes à
              entrega dos bens ou documentação;
            </li>
            <li>
              reclamações acerca de divergências e/ou irregularidades referentes
              aos bens.
            </li>
          </ol>
          <p>
            3.6. Os bens vendidos deverão ser entregues ao usuário comprador nas
            condições ofertadas, no prazo previsto nas Condições de Venda e
            Pagamento do evento.
          </p>
          <p>
            3.7. É de responsabilidade dos agentes de venda diligenciar junto
            aos vendedores proprietários dos bens a resolução de eventuais
            problemas apresentados pelos compradores, em especial com relação à
            entrega dos bens adquiridos e respectiva documentação, se o caso,
            dentro do prazo constante das Condições de Venda e Pagamento e nas
            mesmas condições em que foram ofertados.
          </p>
          <p><b>4. PROCEDIMENTO PARA COMPRA DE BENS NO SITE EBL ONLINE</b></p>
          <p>
            4.1. O usuário cadastrado deverá escolher o leilão de seu
            interesse e solicitar autorização para participar. Para habilitação
            eletrônica não será cobrada tarifa de habilitação, conforme previsto
            nas Condições de Venda e Pagamento do evento. Será exigido que o
            usuário nos envie em formato PDF ou Imagem os seguintes documentos:
          </p>
          <ol type="1">
            <li>Pessoa Física: documento de identidade com foto, CPF e comprovante de residência;</li>
            <li>
              Pessoa Jurídica: Contrato Social ou Estatuto Social com Ata de
              Eleição da Diretoria e cópia do documento de identidade e CPF dos
              representantes legais.
            </li>
          </ol>
          <p>
            4.2. Se houver evento presencial o usuário poderá se habilitar
            pessoalmente no ato do evento portando os documentos acima
            mencionados, conforme o caso.
          </p>
          <p>
            4.3. O usuário deverá ler e aceitar as Condições de Venda e
            Pagamento do evento selecionado. Uma cópia das Condições de Venda e
            Pagamento aceitas será encaminhada ao e-mail cadastrado pelo
            usuário.
          </p>
          <p>
            4.4. Os lances/confirmações de compra/propostas poderão ser
            realizados através do Site EBL Online e/ou presencialmente (na data
            do encerramento do evento, se o caso) simultaneamente e em tempo
            real.
          </p>
          <p>
            4.5. Os lances/confirmações de compra/propostas realizados são
            VINCULANTES, IRREVOGÁVEIS e IRRETRATÁVEIS. O usuário é responsável
            por todos os lances/confirmações de compra/propostas registrados em
            seu nome, pelo que não podem ser anulados e/ou cancelados em nenhuma
            hipótese.
          </p>
          <p>
            4.6. O usuário poderá ofertar mais de um lance/proposta para um
            mesmo bem, prevalecendo sempre o maior lance/proposta ofertados.
          </p>
          <p>
            4.7. Nos leilões o usuário poderá programar lances automáticos, se
            estiver disponível no evento, de forma que, se outro usuário superar
            seu lance, o sistema automaticamente gerará um novo lance para
            aquele usuário, acrescido de um incremento fixo e pré-determinado,
            até um limite máximo definido pelo usuário, com o objetivo de que o
            mesmo tenha certeza de que até o valor estipulado o seu lance será o
            vencedor. Os lances automáticos ficarão registrados no Site EBL
            Online com a data em que forem programados.
          </p>
          <p>
            4.8. Os lotes terão horário previsto de fechamento (relógio
            disponível na seção "tela de lance" do Site EBL Online), caso algum
            lance seja recebido após os 30 segundos o cronômetro irá retroagir para 29
            segundos e assim sucessivamente a cada lance efetuado, para que
            todos os usuários interessados tenham a oportunidade de efetuar
            novos lances. Esta é a configuração padrão, que poderá sofrer
            alterações a cada leilão, que será informada no edital do mesmo.
          </p>
          <p>
            4.9. Durante os leilões, profissionais do Site EBL Online poderão
            auxiliar os usuários no que se fizer necessário, nos eventos físicos
            ou através dos Canais de Atendimento. Comissões e Encargos
            Relacionados aos Eventos
          </p>
          <p>
            4.10. O usuário comprador aceita e se obriga a pagar a comissão
            devida ao Leiloeiro Oficial (5% do valor do bem) e os encargos de
            administração, bem como as comissões devidas aos agentes de venda e
            ao Site EBL Online, se o caso, conforme previsto nas Condições de
            Venda e Pagamento de cada evento.
          </p>
          <p>
            4.11. Todas as regras, prazos e condições de pagamento constarão das
            Condições de Venda e Pagamento do evento.
          </p>
          <p><b>Pagamentos e Inadimplência</b></p>
          <p>
            4.12. Uma vez encerrado o evento, o usuário comprador deverá
            realizar o pagamento dos valores devidos em razão dos bens
            adquiridos, conforme previsto nas Condições de Venda e Pagamento de
            cada evento.
          </p>
          <p>
            4.13. O Site EBL Online, enviará por e-mail, uma fatura contendo
            todos os lotes arrematados pelo arrematante, incluindo suas
            respectivas taxas administrativas, taxa do leiloeiro no final de
            cada leilão.
          </p>
          <p>
            4.14. A fatura será enviada ao e-mail informado previamente no
            cadastro do usuário e conterá todas as informações necessárias,
            inclusive todas as formas de pagamentos habilitadas para o evento.
          </p>
          <p>
            4.15. Caso o usuário comprador não efetue o pagamento dos valores
            devidos, a compra será cancelada, ficando o usuário sujeito às
            penalidades previstas nas Condições de Venda e Pagamento do evento.
            Nesta hipótese o usuário poderá ter seus dados cadastrais incluídos
            nos órgãos de proteção ao crédito.
          </p>
          <p>
            4.16. O usuário inadimplente não será admitido a participar de
            qualquer outro evento divulgado no Site EBL Online, pelo que seu
            cadastro ficará bloqueado e poderá ser suspenso. Caso sejam
            identificados outros cadastros vinculados a este cadastro bloqueado,
            os mesmos serão igualmente bloqueados.
          </p>
          <p>
            4.17. O usuário comprador deverá retirar os bens adquiridos no local
            e prazo constantes das Condições de Venda e Pagamento do evento,
            mediante a apresentação da Nota de Venda/Nota de Arrematação.
          </p>
          <p>
            4.18. No ato da retirada, o usuário comprador ou seu procurador ou a
            transportadora responsável pela retirada deverá conferir o conteúdo
            e as condições dos bens adquiridos, sendo constatada qualquer
            divergência e/ou irregularidade, o fato deverá ser imediatamente
            informado, por escrito, ao agente de venda e ao SITE EBL ONLINE,
            devendo o comprador suspender a retirada até que estejam
            solucionadas as dúvidas existentes.
          </p>
          <p><b>5. SEGURANÇA DO SITE EBL ONLINE</b></p>
          <p>
            5.1. O SITE EBL ONLINE NÃO SOLICITA OU EXIGE QUE SEUS USUÁRIOS
            DIVULGUEM SUAS SENHAS, DADOS DE CARTÃO DE CRÉDITO OU OUTROS DADOS
            BANCÁRIOS POR E-MAIL, TELEFONE OU QUALQUER OUTRO CANAL DE
            ATENDIMENTO PERSONALIZADO. Portanto, se o usuário receber qualquer
            comunicação com esse tipo de abordagem e conteúdo, não deve
            responder, mas sim desconsiderá-la e, se possível, encaminhar o seu
            relato para os Canais de Atendimento do Site EBL Online.
          </p>
          <p>
            5.2. O usuário é o único e exclusivo responsável por manter em
            segurança e sigilo seu Nome de Usuário e senha, evitando assim o uso
            não autorizado de seu cadastro. O usuário deve avisar imediatamente
            o Site EBL Online, por meio dos Canais de Atendimento, em caso de
            suspeita de acesso indevido, ou atividade que possa dar ensejo a
            tal, para que o Site EBL Online possa tomar as devidas providências.
          </p>
          <p>
            5.3. As senhas, Nomes de Usuário e todos os dados fornecidos pelos
            usuários ao Site EBL Online são transmitidos criptografados
            (Certificado de segurança SSL – Secure Socket Layer).
          </p>
          <p>
            5.4. O usuário declara estar ciente da Política de Segurança do Site
            EBL Online disponível em:
          </p>
          <p>https://www.eblonline.com.br/politica-de-seguranca/.</p>
          <p><b>6. PRIVACIDADE E PROTEÇÃO DE DADOS PESSOAIS</b></p>
          <p>
            6.1. O Site EBL Online está comprometido em garantir a privacidade e
            a proteção dos dados pessoais fornecidos por seus usuários, nos
            termos do que estabelece a Lei Geral de Proteção de Dados Pessoais
            nº 13.709/2018 (“LGPD”).
          </p>
          <p>
            6.2. Diante disso, o usuário deve estar ciente de que ao aceitar
            estes Termos e Condições seus dados pessoais serão tratados conforme
            previsto no Aviso de Privacidade disponível em:
            https://www.eblonline.com.br/politica-de-privacidade/.
          </p>
          <p><b>7. RESPONSABILIDADES DO USUÁRIO</b></p>
          <p>
            7.1. Para o devido acesso e uso do Site EBL Online, o usuário
            reconhece e aceita que deverá:
          </p>
          <ol type="1">
            <li>
              arcar com eventuais custos adicionais de conexão e acesso à
              internet para contratação de serviços disponibilizados no Site EBL
              Online e realização de transações de pagamento;
            </li>
            <li>
              fornecer somente informações cadastrais e/ou declarações
              verdadeiras, exatas, atuais e completas;
            </li>
            <li>
              atualizar imediatamente tais informações cadastrais e/ou
              declarações sempre que houver quaisquer alterações nestas, e
            </li>
            <li>
              respeitar os direitos de propriedade intelectual do Site EBL
              Online.
            </li>
          </ol>
          <p>7.2. Além disso, o usuário reconhece ser vedado:</p>
          <ol type="1">
            <li>
              divulgar, ceder ou emprestar seu Nome de Usuário ou senha para
              quaisquer terceiros;
            </li>
            <li>
              violar qualquer lei e/ou regulamento municipal, estadual ou
              nacional em vigor;
            </li>
            <li>
              utilizar o Site EBL Online em atividades não permitidas pela
              legislação ou que resultem em benefícios diretos ou indiretos para
              agentes criminosos e/ou quaisquer práticas ilícitas;
            </li>
            <li>realizar engenharia reversa do Site EBL Online;</li>
            <li>
              assumir a personalidade ou identidade de outra pessoa, física ou
              jurídica;
            </li>
            <li>
              usar o Site EBL Online para carregar, transmitir, divulgar,
              exibir, enviar, ou de qualquer forma tornar disponível qualquer
              conteúdo que viole quaisquer direitos de terceiro;
            </li>
            <li>
              usar o Site EBL Online para carregar, transmitir, divulgar,
              exibir, enviar, ou de qualquer forma tornar disponível qualquer
              tipo de anúncio, propaganda ou material promocional não solicitado
              ou não autorizado pelo Site EBL Online, tais como mensagens não
              solicitadas ou mensagens enviadas em massa;
            </li>
            <li>
              carregar, transmitir, divulgar, exibir, enviar, ou de qualquer
              forma tornar disponível qualquer conteúdo que contenha vírus ou
              qualquer outro código, arquivo ou programa de computador com o
              propósito de interromper, destruir ou limitar a funcionalidade de
              qualquer software, hardware ou equipamento;
            </li>
            <li>
              ameaçar, coagir ou de qualquer forma constranger os demais
              usuários ou vendedores;
            </li>
            <li>violar direitos de sigilo e privacidade de terceiros, e</li>
            <li>
              praticar quaisquer atos que direta ou indiretamente, no todo ou em
              parte, possam causar prejuízo ao Site EBL Online, aos compradores,
              aos agentes de venda, a outros usuários ou a qualquer terceiro.
            </li>
          </ol>
          <p><b>8. PROPRIEDADE INTELECTUAL</b></p>
          <p>
            8.1. O Site EBL Online, bem como seus textos, gráficos, imagens,
            fotos, ilustrações, marcas comerciais, nomes comerciais, marcas de
            serviço, logotipos, informações, código-fonte, layouts, nomes de
            domínio, software, know-how, e outros materiais são todos protegidos
            por direitos de propriedade intelectual (“direitos de propriedade
            intelectual”).
          </p>
          <p>
            8.2. Todos os direitos de propriedade intelectual presentes no Site
            EBL Online são de propriedade da WEBZI PESQUISA & INOVAÇÃO
            TECNOLÓGICA LTDA, CNPJ 37.552.169/0001-64. A exploração comercial,
            transmissão, divulgação, modificação, reprodução, cópia ou quaisquer
            outras formas de utilização comercial ou obtenção de vantagens
            econômicas de tais direitos pelo usuário são rigorosamente
            proibidas.
          </p>
          <p>
            8.3. O uso não autorizado de qualquer marca, logotipo e/ou design do
            Site EBL Online é vedado e sujeitará o usuário às penalidades
            previstas em lei, especialmente na Lei 9.279/1996.
          </p>
          <p><b>9. VALIDADE; ALTERAÇÃO DOS TERMOS E CONDIÇÕES; TÉRMINO</b></p>
          <p>
            9.1. Estes Termos e Condições terão início na data de cadastro do
            usuário no Site EBL Online e serão válidos por prazo indeterminado,
            obrigando as partes, seus herdeiros e sucessores.
          </p>
          <p>
            9.2. O Site EBL Online poderá, a qualquer momento, alterar o
            conteúdo destes Termos e Condições, mediante envio ao usuário de
            comunicação com, no mínimo, 10 dias de antecedência.
          </p>
          <p>
            9.3. Caso o usuário não concorde com as alterações, poderá solicitar
            imediatamente o cancelamento do seu acesso ao Site EBL Online e a
            rescisão destes Termos e Condições, sem a incidências de quaisquer
            penalidades.
          </p>
          <p>
            9.4. O não cancelamento ou o uso do Site EBL Online após comunicação
            da alteração será entendido como aceite do usuário ao novo conteúdo
            dos Termos e Condições.
          </p>
          <p>
            9.5. O Site EBL Online poderá rescindir estes Termos e Condições e
            interromper o fornecimento dos serviços e/ou funcionalidades do Site
            EBL Online, sem aplicação de qualquer penalidade, nos seguintes
            casos:
          </p>
          <ol type="1">
            <li>
              uso indevido de funcionalidades do Site EBL Online por parte do
              usuário, em desconformidade com a regulamentação aplicável ou em
              qualquer tipo de operação ilegal;
            </li>
            <li>
              violação de quaisquer disposições destes Termos e Condições e
              demais termos/políticas do Site EBL Online, em especial da
              cláusula 7.2 destes Termos e Condições;
            </li>
            <li>falecimento do usuário, quando pessoa física;</li>
            <li>
              decretação de falência, recuperação judicial, extrajudicial ou
              insolvência civil do usuário, quando pessoa jurídica;
            </li>
            <li>
              mediante aviso prévio ao usuário, com antecedência de pelo menos
              10 (dez) dias, e 6. nos casos previstos na Cláusula 1.11 destes
              Termos e Condições.
            </li>
          </ol>
          <p>
            9.6. O usuário poderá solicitar o cancelamento do seu acesso ao Site
            EBL Online a qualquer momento, sendo desnecessária a apresentação de
            justificativa.
          </p>
          <p><b>10. LIMITE DE RESPONSABILIDADE</b></p>
          <p>
            10.1. O Site EBL Online viabiliza a intermediação e a aproximação de
            agentes de venda e compradores e, portanto, não se responsabilizam
            por prejuízos ou danos advindos das transações efetuadas entre os
            agentes de venda e os compradores, limitando a sua atuação à
            prestação dos serviços pelos quais expressamente se obrigam.
            Considerando que o Site EBL Online não figura como parte nas
            transações de venda e compra de bens realizadas entre os usuários do
            Site EBL Online, a responsabilidade por todas as obrigações delas
            decorrentes, sejam fiscais, trabalhistas, consumeristas ou de
            qualquer outra natureza, será exclusivamente do respectivo usuário.
          </p>
          <p>
            10.2. O Site EBL Online não faz quaisquer declarações, nem
            asseguram, endossam, garantem ou assumem a responsabilidade pela
            qualidade satisfatória, comercialidade e adequação do Site EBL
            Online, nem por quaisquer bens divulgados ou oferecidos por agentes
            de venda no, ou por meio dos serviços do Site EBL Online.
          </p>
          <p>
            10.3. O agente de venda é responsável pela origem, existência,
            qualidade, segurança, quantidade, legitimidade, autenticidade, por
            vícios ou defeitos dos bens ofertados, por todo conteúdo dos
            anúncios que veicular no Site EBL Online, incluindo a descrição dos
            bens, débitos, Condições de Venda e Pagamento do Evento, bem como
            pela entrega dos bens nas condições ofertadas.
          </p>
          <p>
            10.4. O usuário está ciente e de acordo que o Site EBL Online não se
            responsabiliza pelos bens vendidos, pelo conteúdo dos anúncios
            veiculados no Site EBL Online, pela transação de venda e compra, bem
            como pela entrega dos bens aos compradores.
          </p>
          <p>
            10.5. O usuário reconhece que todo sistema que depende da rede
            mundial de computadores está sujeito a falhas e indisponibilidade.
            Assim, não haverá responsabilidade do Site EBL Online no caso de
            indisponibilidade do Site EBL Online, sendo que nessas hipóteses o
            usuário poderá entrar em contato com o Site EBL Online através dos
            Canais de Atendimento.
          </p>
          <p><b>11. INDENIZAÇÃO</b></p>
          <p>
            11.1 O usuário concorda em isentar, indenizar, defender e manter o
            Site EBL Online e seus respectivos diretores, agentes, sócios e
            funcionários indenes de qualquer prejuízo, responsabilidade, ação
            judicial ou demanda, incluindo honorários advocatícios, decorrentes
            do uso indevido do Site EBL Online ou de violação destes Termos e
            Condições.
          </p>
          <p>
            11.2. O usuário agente de venda concorda em isentar, indenizar,
            defender e manter o Site EBL Online e seus respectivos diretores,
            agentes, sócios e funcionários indenes de qualquer prejuízo,
            responsabilidade, ação judicial ou demanda, incluindo honorários
            advocatícios, decorrentes da venda dos bens no Site EBL Online, em
            especial no tocante a reinvindicações com relação à origem,
            existência, qualidade, segurança, quantidade, legitimidade,
            autenticidade, vícios ou defeitos dos bens ofertados e conteúdo dos
            anúncios veiculados no Site EBL Online.
          </p>
          <p><b>12. COMUNICAÇÕES</b></p>
          <p>
            12.1. O usuário autoriza o Site EBL Online a contatá-lo por qualquer
            meio (telefone e e-mail) para enviar comunicações de seu interesse a
            respeito do Site EBL Online, especialmente em caso de prevenção a
            fraudes.
          </p>
          <p>
            12.2. O usuário reconhece, desde já, que todo acesso ao Site EBL
            Online ocorrerá apenas mediante autenticação própria, nos termos da
            Cláusula 1 acima, de forma que ficam inteiramente validadas e com
            força probatória perante qualquer juízo ou tribunal todas as
            comunicações enviadas e/ou disponibilizadas pelo Site EBL Online, as
            quais serão consideradas válidas e passarão a integrar este
            documento para todos os fins e efeitos de direito.
          </p>
          <p><b>13. MANUTENÇÃO E INTERRUPÇÃO DE FUNCIONALIDADES</b></p>
          <p>
            13.1. O Site EBL Online se reserva o direito de promover, a qualquer
            momento, manutenções, modificações ou atualizações em seu sistema,
            de modo que os serviços e/ou funcionalidades do Site EBL Online
            poderão ficar temporariamente indisponíveis.
          </p>
          <p>
            13.2. Além disso, o Site EBL Online poderá interromper ou alterar o
            fornecimento de determinadas funcionalidades e/ou serviços do Site
            EBL Online, mediante o envio de notificação ao usuário sobre tais
            alterações com antecedência mínima de 10 dias.
          </p>
          <p><b>14. DISPOSIÇÕES GERAIS</b></p>
          <p>
            14.1. Estes Termos e Condições estão integralmente disponíveis para
            consulta no Site EBL Online e poderão ser acessados pelo usuário a
            qualquer momento.
          </p>
          <p>
            14.2. Fica eleito pelo Site EBL Online e pelo usuário o Foro Central
            da Comarca da Capital do Estado do Rio de Janeiro para dirimir
            quaisquer questões relativas aos presentes Termos e Condições.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.top {
  padding: 200px 20px 0;
}
</style>
